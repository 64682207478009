import React from "react";

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import "./app.scss";

function App() {
    return (
        <Container>
            <Row>
                <Col>
                    <div className="img-wrap">
                        <div className="profile-image">&nbsp;</div>
                    </div>
                </Col>
                <Col>
                    <div className="name-wrap">
                        <span className="firstname">robbert</span>
                        <span className="lastname">van staveren</span>
                    </div>
                    <div className="line-doodle">&nbsp;</div>
                </Col>
            </Row>
        </Container>
    );
}

export default App;
